import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import { contactPhone } from 'app/constants';
import ContactUs from 'styleguide/components/ContactUs/ContactUs';
import { contactUsPath, servicePath } from 'bundles/App/routes';

const MobileFooter = () => (
  <Grid.Container className="m-0 p-0">
    <Grid>
      <Grid.Row className="m-0 p-0">
        <Grid.Col className="flex min-h-[200px] flex-col items-center justify-center !border-b !border-t border-solid !border-gray-50">
          <ContactUs
            type="onPage"
            phone={contactPhone}
            emailUsUrl={contactUsPath}
            phoneUrl={`tel:${contactPhone}`}
            shippingInfoUrl={servicePath.rushCriticalPath}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default MobileFooter;
