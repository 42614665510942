import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Span from 'styleguide/components/Span/Span';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import Tooltip from 'styleguide/components/Tooltip/Tooltip';
import { clickSignUpPdp } from 'api/gtm';
import { isProductPage } from 'libs/utils/pageUtils';
import AppContext from 'contexts/AppContext/AppContext';

const ToolTipContent = () => (
  <div>
    <p className="paragraph-desktop">
      <strong>
        How to Redeem
        <br />
      </strong>
    </p>
    <ol className="ml-5">
      <li>Register an account.</li>
      <li>Log into your account.</li>
      <li>Enter the promo code into your shopping cart.</li>
    </ol>
    <p className="paragraph-desktop">
      <strong>
        Terms &amp; Conditions
        <br />
      </strong>
    </p>
    <ul className="list-inside">
      <li>Must have never placed an order with Printivity, formerly MGX Copy.</li>
      <li>The 20% off promotion only applies to a single line item.</li>
      <li>The maximum discount is $500.</li>
      <li>Promotion does not apply to Hardcover Books</li>
      <li>Promotion cannot be used with other discounts.</li>
      <li>Promotion cannot be used more than once.</li>
      <li>
        We reserve the right to verify eligibility and adjust your order total even after you&apos;ve placed
        your order.
      </li>
    </ul>
  </div>
);

const SubscriptionForm = () => {
  const appContext = React.useContext(AppContext);
  const location = useLocation();

  return (
    <div className="w-full border border-solid border-gray-50 px-0 py-[1em] mt-10">
      <div className="flex flex-wrap justify-around sm:w-full lg:justify-center">
        <div className="sm:hidden lg:block lg:basis-3/12" />
        <div
          className="flex flex-col items-start justify-center text-left !text-base !leading-[1em]
       md:items-start md:text-left md:!text-lg lg:basis-3/12 -sm:w-full -sm:items-center -sm:text-center"
        >
          <Span className="!mb-2 !font-hvBold !text-xl">Get up to 20% off your first order.</Span>
          <div className="flex items-center mb-2">
            <Span className="max-w-xs !font-hvRoman !text-base mr-2">
              We&apos;ll send info on exclusive promotions, product launches, and much more.
            </Span>
          </div>
          <Tooltip
            type="text"
            position="bottom"
            contentToolTip={<ToolTipContent />}
            message="See rules and conditions"
          />
        </div>
        <div className="!flex flex-row items-center justify-center  lg:basis-3/12 4xl:justify-end -sm:w-full">
          <ButtonAsNativeLink
            buttonType="link"
            target={{
              pathname: '/sign-up',
              state: { from: location.pathname },
            }}
            color="orange"
            className="!font-hvMedium !text-sm -lg:mt-[0.5em]"
            onClick={() => {
              if (isProductPage(location.pathname, appContext.store.cmsPages)) {
                clickSignUpPdp();
              }
            }}
          >
            Sign Up - Get up to 20% Off
          </ButtonAsNativeLink>
        </div>
        <div className="sm:hidden lg:block lg:basis-3/12" />
      </div>
    </div>
  );
};

export default SubscriptionForm;
