import * as React from 'react';
import Span from 'styleguide/components/Span/Span';
import { LinkWithIcon } from './ContactUs';
import { IconShipping, IconMail } from 'styleguide/icons';
import A from 'styleguide/components/Links/A';
import Link from 'styleguide/components/Links/Link';
import { helpCenterPath } from 'bundles/App/routes';

interface Props {
  phone: string;
  phoneUrl: string;
  shippingInfoUrl: string;
  emailUsUrl: string;
}

const ContactUsPage = ({ phoneUrl, phone, shippingInfoUrl, emailUsUrl }: Props) => (
  <div className="w-full">
    <div className="flex flex-col items-center justify-center px-0 py-5">
      <Span className="text-sm leading-3">Get help from your print experts.</Span>
      <A
        href={phoneUrl}
        color="blue"
        underline="none"
        className="block pt-3 text-4xl leading-9 tracking-tight"
      >
        {phone}
      </A>
      <Span className="pb-4">
        <Link className="text-lg leading-8" to={helpCenterPath} color="blue" underline="always">
          Help Center
        </Link>
      </Span>
      <div className="flex h-8 w-full flex-row justify-around">
        <LinkWithIcon
          Icon={<IconShipping size="lg" color="gray" className="h-8 w-8" />}
          url={shippingInfoUrl}
          title="Rush shipping info"
        />
        <LinkWithIcon Icon={<IconMail size="lg" color="gray" />} url={emailUsUrl} title="Email us" />
      </div>
    </div>
  </div>
);

export default ContactUsPage;
