import * as React from 'react';
import ContactUsMobile from './ContactUsMobile';
import ContactUsWide from './ContactUsWide';
import ContactUsPage from './ContactUsPage';
import Link from 'styleguide/components/Links/Link';

interface PropsLinkWithIcon {
  url: string;
  Icon: React.ReactNode;
  title: string;
}

export const LinkWithIcon = ({ Icon, url, title }: PropsLinkWithIcon) => (
  <div className="flex items-center">
    {Icon}
    <Link to={url} color="black" underline="always" className="pl-2 text-sm leading-3">
      {title}
    </Link>
  </div>
);

interface Props {
  type?: 'mobileMenu' | 'desktopMenu' | 'onPage';
  phone: string;
  phoneUrl: string;
  shippingInfoUrl: string;
  emailUsUrl: string;
}

const ContactUs = ({ type, ...props }: Props) =>
  (type === 'desktopMenu' && <ContactUsWide {...props} />) ||
  (type === 'mobileMenu' && <ContactUsMobile {...props} />) ||
  (type === 'onPage' && <ContactUsPage {...props} />);

export default ContactUs;
