import * as React from 'react';
import Span from 'styleguide/components/Span/Span';
import A from 'styleguide/components/Links/A';
import Link from 'styleguide/components/Links/Link';
import { helpCenterPath } from 'bundles/App/routes';

interface Props {
  phone: string;
  phoneUrl: string;
  shippingInfoUrl: string;
  emailUsUrl: string;
}

const ContactUsMobile = ({ phoneUrl, phone, shippingInfoUrl, emailUsUrl }: Props) => (
  <div className="px-4 pb-4 pt-16">
    <div className="flex justify-between">
      <Span className="text-lg leading-4">Need help?</Span>
      <A href={phoneUrl} color="blue" underline="none" className="!text-lg leading-4">
        {phone}
      </A>
    </div>
    <div className="flex justify-center pt-4">
      <Link to={helpCenterPath} color="blue" underline="always" className="!text-lg leading-4">
        Help Center
      </Link>
    </div>
    <div className="flex justify-between pt-5 text-sm">
      <Link to={shippingInfoUrl} color="black" underline="always">
        Rush shipping info
      </Link>
      <A href={emailUsUrl} color="black" underline="always">
        Email us
      </A>
    </div>
  </div>
);
export default ContactUsMobile;
