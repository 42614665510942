import * as React from 'react';
import Span from 'styleguide/components/Span/Span';
import { LinkWithIcon } from './ContactUs';
import { IconShipping, IconMail } from 'styleguide/icons';
import Link from 'styleguide/components/Links/Link';
import { helpCenterPath } from 'bundles/App/routes';

interface Props {
  phone: string;
  shippingInfoUrl: string;
  emailUsUrl: string;
}

const ContactUsWide = ({ phone, shippingInfoUrl, emailUsUrl }: Props) => (
  <div className="flex w-96 flex-col items-center bg-additional-graySmoke px-5 pb-0 pt-5 font-normal">
    <Span>Get help from your print experts.</Span>
    <Span className="pb-4 pt-2 text-4xl leading-9 tracking-tight">{phone}</Span>
    <Span className="pb-4 pt-2 text-4xl leading-9 tracking-tight">
      <Link to={helpCenterPath} color="blue" underline="active">
        Help Center
      </Link>
    </Span>
    <hr />
    <div className="flex h-16 w-[calc(100%+2.5rem)] flex-row justify-between border-t border-solid border-gray-300 px-8">
      <LinkWithIcon
        Icon={<IconShipping size="lg" color="gray" className="h-8 w-8" />}
        url={shippingInfoUrl}
        title="Rush shipping info"
      />
      <LinkWithIcon Icon={<IconMail size="lg" color="gray" />} url={emailUsUrl} title="Email us" />
    </div>
  </div>
);

export default ContactUsWide;
